import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route,Routes,Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
function Header(props){
  //console.log(props.group);
  const list = props.group.map((product, key) => <li className="nav-item" key={key}>
    {/* <a className="nav-link" href="#"> */}
    <Link to={`/category/${key}`} className="nav-link"> 
      <i className="fa fa-microchip"></i>
      {product.name}
    </Link>      
      {/* </a> */}
  </li>)
  return <div className="header">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <nav className="navbar bg-light">
            <ul className="navbar-nav">  
            {list}
            </ul>
          </nav>
        </div>
        <div className="col-md-9 header">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100 header-img"
                  src={require('./img/Banner1.png')}
                  alt="First slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100 header-img"
                  src={require('./img/Banner2.webp')}
                  alt="Second slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100 header-img"
                  src={require('./img/Banner2.jpg')}
                  alt="Third slide"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
}
function BottomBar(){
  return <div className="bottom-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="logo">
                  <Link to="/" className="nav-item nav-link"><img src={require('./img/logo.png')} alt="Logo" /></Link>
                  {/* <a href="#">
                    <img src={require('./img/logo.png')} alt="Logo" />
                  </a> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="search">
                  <input type="text" placeholder="Search" />
                  <button><i className="fa fa-search"></i></button>
                </div>
              </div>
              <div className="col-md-3">
                <div className="user">
                  <a href="wishlist.html" className="btn wishlist">
                    <i className="fa fa-heart"></i>
                    <span>(0)</span>
                  </a>
                  <a href="cart.html" className="btn cart">
                    <i className="fa fa-shopping-cart"></i>
                    <span>(0)</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
}
function NavBar(){
  return(
  <div className="nav">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md bg-dark navbar-dark">
            <a href="#" className="navbar-brand">MENU</a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-expanded="false"
              aria-controls="navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto">
                {/* <a href="#" className="nav-item nav-link"
                  >Trang chủ</a
                > */}
                <Link to="/" className="nav-item nav-link">Trang chủ</Link>
                <a href="#" className="nav-item nav-link"
                  >Phần mềm</a
                >
                <a href="#" className="nav-item nav-link">LabVIEW tips</a>
                <a href="#" className="nav-item nav-link">LabVIEW learn</a>
                <a href="#" className="nav-item nav-link">Công cụ</a>
              </div>
            </div>
          </nav>
        </div>
      </div>)
}
function SupportTab(){
  return(
  <div className="call-to-action">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h1>Gọi hỗ trợ kỹ thuật</h1>
        </div>
        <div className="col-md-6">
          <a href="tel:0123456789">0917387495</a>
        </div>
      </div>
    </div>
  </div>
  )
}
function IdexProductView(props){
  const grouplist = props.group.map((group, groupkey) => 
  <div className="featured-product product">
    <div className="container-fluid">
      <div className="section-header">
        <h1>
          <Link to={`/category/${groupkey}`}>{ group.name }</Link>
          {/* <a href="#"></a> */}
        </h1>
      </div>
      <div className="row align-items-center product-slider product-slider-4">
        { group.items.map((item,key) =>
          <div className="col-lg-3">
            <Producdiv item={item} key={key}/>
          </div>
        )}
      </div>
    </div>
  </div>)
  return <>{ grouplist }</>
}
function Producdiv(props){
  return <div className="product-item">
  <div className="product-title">
    <Link to={`/product/${props.item.item_key}`}>{ props.item.item_name }</Link>
    <div className="ratting">
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
      <i className="fa fa-star"></i>
    </div>
  </div>
  <div className="product-image">
    <a href="#">
      <img src={ props.item.item_image } alt={ props.item.item_name }/>
    </a>
    <div className="product-action">
      <a href="#"><i className="fa fa-cart-plus"></i></a>
      <a href="#"><i className="fa fa-heart"></i></a>
      <a href="#"><i className="fa fa-search"></i></a>
    </div>
  </div>
  <div className="product-price">
    <h3>{ props.item.item_price }<span>đ</span></h3>
    <a className="btn" href=""><i className="fa fa-shopping-cart"></i>Thêm</a>
  </div>  
</div>
} 
function Home(props){
    
    return (
      <>
       <div>
        <BottomBar />
        <NavBar />
        <Header group={props.data}/>
        <SupportTab />
        <IdexProductView group={props.data}/>
        <Footer />
      </div>
      </>
    )
}
function Breadcrumb(props){
  
  return(
  <div className="breadcrumb-wrap">
    <div className="container-fluid">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Trang chủ/</Link>
          <Link to={`/category/${props.group.key}`}>{props.group.name}/</Link>
          {props.productname?<Link to='#'>{props.productname}</Link>:null}
        </li>
      </ul>
    </div>
  </div>)
}
function CategoryProductView(props){
  return(
    <div className="product-view">
      <div className="container-fluid">
          <div className="row">
              <div className="col-lg-9">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="product-view-top">
                              <div className="row">
                                  <div className="col-md-4">
                                      <div className="product-search">
                                          <input type="email" value="Search"/>
                                          <button><i className="fa fa-search"></i></button>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="product-short">
                                          <div className="dropdown">
                                              <div className="dropdown-toggle" data-toggle="dropdown">Product short by</div>
                                              <div className="dropdown-menu dropdown-menu-right">
                                                  <a href="#" className="dropdown-item">Newest</a>
                                                  <a href="#" className="dropdown-item">Popular</a>
                                                  <a href="#" className="dropdown-item">Most sale</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-4">
                                      <div className="product-price-range">
                                          <div className="dropdown">
                                              <div className="dropdown-toggle" data-toggle="dropdown">Product price range</div>
                                              <div className="dropdown-menu dropdown-menu-right">
                                                  <a href="#" className="dropdown-item">$0 to $50</a>
                                                  <a href="#" className="dropdown-item">$51 to $100</a>
                                                  <a href="#" className="dropdown-item">$101 to $150</a>
                                                  <a href="#" className="dropdown-item">$151 to $200</a>
                                                  <a href="#" className="dropdown-item">$201 to $250</a>
                                                  <a href="#" className="dropdown-item">$251 to $300</a>
                                                  <a href="#" className="dropdown-item">$301 to $350</a>
                                                  <a href="#" className="dropdown-item">$351 to $400</a>
                                                  <a href="#" className="dropdown-item">$401 to $450</a>
                                                  <a href="#" className="dropdown-item">$451 to $500</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      { props.group.items.map((item,key) =>
                      <div className="col-md-4">
                          <div className="product-item">
                          <Producdiv item={item} />
                          </div>
                      </div>
                      )}
                  </div>
                  
                  {/* <!-- Pagination Start --> */}
                  <div className="col-md-12">
                      <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center">
                              <li className="page-item disabled">
                                  <a className="page-link" href="#" tabindex="-1">Previous</a>
                              </li>
                              <li className="page-item active"><a className="page-link" href="#">1</a></li>
                              <li className="page-item"><a className="page-link" href="#">2</a></li>
                              <li className="page-item"><a className="page-link" href="#">3</a></li>
                              <li className="page-item">
                                  <a className="page-link" href="#">Next</a>
                              </li>
                          </ul>
                      </nav>
                  </div>
                  {/* <!-- Pagination Start --> */}
              </div>  
              <div className="col-lg-3 sidebar">         
              <Sidebar allgroups={props.allgroups}/>
              </div> 
          </div>
      </div>
    </div> 
  )
}
function Sidebar(props){
    {/* <!-- Side Bar Start --> */}
    
    return( 
    <div className="sidebar-widget category">
          <h2 className="title">Danh mục</h2>
          <nav className="navbar bg-light">
              <ul className="navbar-nav">
                {props.allgroups.map((group,id)=>
                  <li className="nav-item">
                      <Link className="nav-link" to={`/category/${id}`}><i className="fa fa-microchip"></i>{group.name}</Link>
                  </li>)
                }
              </ul>
          </nav>
      </div>)
}
function Category(props){
  let { groupId } = useParams();
  const selectgroup=props.data.filter(function(creature) {
    return creature.key == groupId;
  })[0];
  //console.log(selectgroup);
  return(
    <div>
      <BottomBar />
      <NavBar />
      <Breadcrumb group={selectgroup} productname=''/>
      <CategoryProductView group={selectgroup} allgroups={props.data}/>
    </div>

  )
}
function findGroupName(itemId, objectGroups) {
  console.log(itemId);
  const groupItems = objectGroups.map((group) => {
    const foundItem = group.items.find((item) => item.item_key === itemId);
    if (foundItem) {
      return group;
    }
  });
  return groupItems.filter((group) => group !== undefined);
}
function Footer(){
  return (
    <div class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="footer-widget">
                    <h2>Liên hệ</h2>
                    <div class="contact-info">
                        <p><i class="fa fa-map-marker"></i>TPHCM</p>
                        <p><i class="fa fa-envelope"></i>vtrung454@gmail.com</p>
                        <p><i class="fa fa-phone"></i>0917387495</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="footer-widget">
                    <h2>Follow Us</h2>
                    <div class="contact-info">
                        <div class="social">
                            <a href=""><i class="fab fa-twitter"></i></a>
                            <a href=""><i class="fab fa-facebook-f"></i></a>
                            <a href=""><i class="fab fa-linkedin-in"></i></a>
                            <a href=""><i class="fab fa-instagram"></i></a>
                            <a href=""><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h2>Company Info</h2>
                    <ul>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms & Condition</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h2>Purchase Info</h2>
                    <ul>
                        <li><a href="#">Pyament Policy</a></li>
                        <li><a href="#">Shipping Policy</a></li>
                        <li><a href="#">Return Policy</a></li>
                    </ul>
                </div>
            </div> */}
        </div>
    </div>
</div>
  )
}
function ProductDetailView(props){
  const itemselected = props.group.items.filter((item)=>item.item_key==props.productid)[0];
  const remainitems = props.group.items.filter((item)=>item.item_key!=props.productid);
  return<>
    <div className="product-detail">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="product-detail-top">
                            <div className="row align-items-center">
                                <div className="col-md-5">
                                    <div className="product-slider-single normal-slider">
                                        <img src={itemselected.item_image} alt={itemselected.item_name}/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="product-content">
                                        <div className="title"><h2>{itemselected.item_name }</h2></div>
                                        <div className="ratting">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="price">
                                            <h4>Giá:</h4>
                                            <p>{ itemselected.item_price }</p>
                                        </div>
                                        <div className="quantity">
                                            <h4>Số lượng:</h4>
                                            <div className="qty">
                                                <button className="btn-minus"><i className="fa fa-minus"></i></button>
                                                <input type="text" value="1"/>
                                                <button className="btn-plus"><i className="fa fa-plus"></i></button>
                                            </div>
                                        </div>
                                        <div className="action">
                                            <a className="btn" href="#"><i className="fa fa-shopping-cart"></i>Add to Cart</a>
                                            <a className="btn" href="#"><i className="fa fa-shopping-bag"></i>Buy Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row product-detail-bottom">
                            <div className="col-lg-12">
                                <ul className="nav nav-pills nav-justified">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="pill" href="#description">Mô tả</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#specification">Thông số kỹ thuật</a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div id="description" className="container tab-pane active">
                                      <div dangerouslySetInnerHTML={{ __html: itemselected.item_description }} />
                                    </div>
                                    <div id="specification" className="container tab-pane fade">
                                      <div dangerouslySetInnerHTML={{ __html: itemselected.item_specification }} />
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="product">
                            <div className="section-header">
                                <h1>Sản phẩm liên quan</h1>
                            </div>
                            <div className="row align-items-center product-slider product-slider-3">
                                {remainitems.map((item)=><div className="col-lg-4"><Producdiv item={item}  /></div> )}               
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 sidebar">
                        <Sidebar allgroups={props.allgroups}/>
                        <div className="sidebar-widget tag">
                            <h2 className="title">Tags Related</h2>
                            <a href="#">Arduino</a>
                            <a href="#">Vi điều khiển</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
  </>
}    
function Product(props){
  let { productid } = useParams();
  const groupselected= findGroupName(productid,props.data)[0]; //find group object not only name
  //filter product name for breadcrumb
  console.log(groupselected);
  const itemselected = groupselected.items.filter((item)=>item.item_key==productid)[0];
  return(
    <div>
      <BottomBar />
      <NavBar />
      <Breadcrumb group={groupselected} productname={itemselected.item_name}/>
      <ProductDetailView group={groupselected} productid={productid} allgroups={props.data}/>
    </div>  
      )
}
function News(props){
  const [allpost, setPost] = useState([
    { 
      post_title:'',
      post_detail:'',
      post_img:'',
      post_summarize:'',
      post_tag:'',
  }]);
  const getData = async () => {
    const resp = await fetch('http://127.0.0.1:8000/api/post');
    const json = await resp.json();
    setPost(json);
  }
  useEffect(() => {
    getData();
  }, []);
  return(
  <>
  <BottomBar />
  <NavBar />
  <div className="container mt-4">
    <h1>Labview lesson learn</h1>
    <div className="row">
      <div className="col-md-8">
        <div className="card">
        <div className="card-body">
          <h2 className="card-title">{allpost[0].post_title}</h2>
          <p className="text-muted">Posted on January 1, 2024</p>
          <img src="https://via.placeholder.com/800x400" className="img-fluid" alt="Blog Post Image"/>
          <p>{allpost[0].post_summarize}</p>
          <a href="#" className="btn btn-primary">Read More</a>
        </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">About Me</h5>
            <p className="card-text">I am a LabVIEW developer.</p>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title">Recent Posts</h5>
            <ul className="list-unstyled">
              <li><a href="#">Post Title 1</a></li>
              <li><a href="#">Post Title 2</a></li>
              <li><a href="#">Post Title 3</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  );
}
function App() {
  const [loading,setLoading]=useState(true);
  const [data, setData] = useState([
    { name:'Arduino',
    description:'',
    key:0,
    items:[],
  }]);
  const getData = async () => {
    const resp = await fetch('https://electronshopvn.com/api/group');
    const json = await resp.json();
    const modifiedData = await json.map((item, groupindex) => {
      // Add a key property to each object
      item.items.forEach((obj,itemkey) => {
        obj.item_key=`${groupindex}_${itemkey}`  // Spread operator to create a new object
      });
      return { ...item, key: groupindex };
    });
    setData(modifiedData);
    //console.log(modifiedData);
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
    <Routes>
      <Route exact path="/" element={!loading && <Home data={data} />} />
      <Route path="/category/:groupId" element={<Category data={data} />} />
      <Route path="/product/:productid" element={<Product data={data} />} />
      <Route path="/news" element={<News />} />
    </Routes>
    </>
  );
}

export default App;
